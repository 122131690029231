var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"mx-auto",attrs:{"to":_vm.isAdmin()
      ? {
          name: 'show-' + [_vm.namespace],
          params: { id: _vm.item.id }
        }
      : ''}},[_c('v-card-title',{staticClass:"d-flex"},[(_vm.item.name)?_c('span',{staticClass:"font-weight-light text-capitalize text-truncate"},[_c('v-badge',{attrs:{"color":_vm.item.active ? 'success' : 'error',"text-color":"white","overlap":"","inline":"","avatar":"","dot":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(_vm.icon))])],1),_vm._v(" "+_vm._s(_vm.item.name))],1):_vm._e()]),_c('v-img',{staticClass:"white--text align-end",attrs:{"contain":"","height":_vm.imageHeight,"src":_vm.item.pictureUrl},on:{"error":function($event){_vm.item.pictureUrl = require('@/assets/picture-loading-failed-1.png')}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}])}),_c('v-card-subtitle',{staticClass:"mx-auto align-center justify-space-between d-flex"},[_c('span',{staticClass:"text-left"},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-calendar")]),_vm._v(_vm._s(_vm.formatDate(_vm.item.startDate)))],1),_vm._v("- "),_c('span',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.item.endDate))),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-calendar")])],1)]),(_vm.showActions)?_c('v-divider'):_vm._e(),(!_vm.isAdmin())?_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","dark":"","color":"success","to":{
        name: 'create-sales',
        params: { id: _vm.item.id, products: _vm.item.products }
      }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-sale")]),_vm._v(" "+_vm._s(_vm.$t("app.registerSale"))+" ")],1)],1):_vm._e(),(_vm.showActions && _vm.isAdmin())?_c('v-card-actions',[_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"pa-1",attrs:{"to":{
            name: 'show-' + [_vm.namespace],
            params: { id: _vm.item.id }
          },"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,false,3825398752)},[_c('span',[_vm._v(_vm._s(_vm.$t("tooltip.show")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"pa-1",attrs:{"to":{
            name: 'edit-' + [_vm.namespace],
            params: { id: _vm.item.id }
          },"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,false,580403483)},[_c('span',[_vm._v(_vm._s(_vm.$t("tooltip.edit")))])])],1):_vm._e(),(_vm.confirmDeleteDialog)?_c('delete-dialog',{attrs:{"dialog":_vm.confirmDeleteDialog},on:{"update:dialog":function($event){_vm.confirmDeleteDialog=$event},"dialog-cancel":function($event){_vm.confirmDeleteDialog = false},"dialog-update":_vm.deleteItem}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }