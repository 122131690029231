<template>
  <v-card
    class="mx-auto"
    :to="
      isAdmin()
        ? {
            name: 'show-' + [namespace],
            params: { id: item.id }
          }
        : ''
    "
  >
    <v-card-title class="d-flex">
      <span class="font-weight-light text-capitalize text-truncate" v-if="item.name">
        <v-badge
          :color="item.active ? 'success' : 'error'"
          text-color="white"
          overlap
          inline
          avatar
          dot
        >
          <v-icon left>{{ icon }}</v-icon></v-badge
        >
        {{ item.name }}</span
      >
    </v-card-title>
    <v-img
      class="white--text align-end"
      contain
      :height="imageHeight"
      :src="item.pictureUrl"
      @error="item.pictureUrl = require('@/assets/picture-loading-failed-1.png')"
    >
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
        </v-row>
      </template>
    </v-img>
    <v-card-subtitle class="mx-auto align-center justify-space-between d-flex">
      <span class="text-left">
        <v-icon left>mdi-calendar</v-icon>{{ formatDate(item.startDate) }}</span
      >-
      <span class="text-right">
        {{ formatDate(item.endDate) }}<v-icon right>mdi-calendar</v-icon></span
      ></v-card-subtitle
    >
    <v-divider v-if="showActions"> </v-divider>
    <v-card-actions v-if="!isAdmin()">
      <v-spacer></v-spacer>
      <v-btn
        depressed
        dark
        color="success"
        :to="{
          name: 'create-sales',
          params: { id: item.id, products: item.products }
        }"
      >
        <v-icon left>mdi-sale</v-icon>
        {{ $t("app.registerSale") }}
      </v-btn>
    </v-card-actions>
    <v-card-actions v-if="showActions && isAdmin()">
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            :to="{
              name: 'show-' + [namespace],
              params: { id: item.id }
            }"
            class="pa-1"
            icon
          >
            <v-icon>mdi-eye</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("tooltip.show") }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            :to="{
              name: 'edit-' + [namespace],
              params: { id: item.id }
            }"
            class="pa-1"
            icon
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("tooltip.edit") }}</span>
      </v-tooltip>
      <!--<v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            class="error--text pa-1"
            icon
            v-on:click.prevent="confirmDeleteDialog = true"
          >
            <v-icon color="error">mdi-trash-can-outline</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("tooltip.delete") }}</span>
      </v-tooltip>-->
    </v-card-actions>

    <delete-dialog
      v-if="confirmDeleteDialog"
      :dialog.sync="confirmDeleteDialog"
      @dialog-cancel="confirmDeleteDialog = false"
      @dialog-update="deleteItem"
    ></delete-dialog>
  </v-card>
</template>

<script>
export default {
  components: {
    DeleteDialog: () => import("@/components/core/dialogs/DeleteDialog")
  },
  data() {
    return {
      confirmDeleteDialog: false
    };
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    imageHeight: {
      type: String,
      required: false,
      default: "200px"
    },
    limitText: {
      type: String,
      required: false,
      default: "40"
    },
    showActions: {
      type: Boolean,
      required: false,
      default: false
    },
    namespace: {
      type: String,
      required: false,
      default: ""
    },
    showBackArrow: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    deleteItem() {
      this.$emit("delete-item", this.item.id);
      this.confirmDeleteDialog = false;
    }
  }
};
</script>

<style scoped>
.break-all-words >>> p {
  margin-bottom: 0 !important;
}
.break-all-words {
  word-break: break-all;
}
</style>
